<template>
  <div class="container">
    <el-tabs value="1" @tab-click="tabClick">
      <el-tab-pane label="已预约" name="1"></el-tab-pane>
      <el-tab-pane label="已使用" name="2"></el-tab-pane>
      <el-tab-pane label="已取消" name="3"></el-tab-pane>
    </el-tabs>

    <div style="float: left;">
      <el-radio-group v-model="pageInfo.isToday" @change="changeToday">
        <el-radio :label="1">今日</el-radio>
        <el-radio :label="0">全部</el-radio>
      </el-radio-group>
      <el-divider direction="vertical"></el-divider>
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button
        v-loading="exportLoading"
        type="primary"
        icon="el-icon-upload2"
        @click="exportToExcel"
      >
        导出
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="depid">
          <el-cascader
            v-model="query.depid"
            :options="departmentList"
            clearable
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              emitPath: false
            }"
            placeholder="请选择所属部门"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入预约人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="telephone">
          <el-input
            v-model="query.telephone"
            placeholder="请输入手机号码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="movieName">
          <el-input
            v-model="query.movieName"
            placeholder="请输入电影名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="orderTime">
          <el-date-picker
            v-model="query.orderTime"
            clearable
            type="date"
            align="left"
            placeholder="请选择预定日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="44" />
      <el-table-column label="预定人名称" prop="name" />
      <el-table-column label="所属部门" prop="department" />
      <el-table-column label="警号" prop="policeCard" />
      <el-table-column label="电话号码" prop="telphone" />
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="180"
        sortable
      />
      <el-table-column label="预定时间" prop="orderTime" width="180" sortable>
        <template slot-scope="scope">
          <el-tag type="warning">
            <b>{{ scope.row.orderTime }}</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="预定电影名称" prop="movieName" width="180" />
      <el-table-column label="预定票数" prop="nums" align="center" />
      <el-table-column
        label="座位号"
        prop="formatterSeat"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <el-tag
            v-for="(item, index) in scope.row.formatterSeat"
            :key="index"
            class="ml10 mt10"
          >
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import { getAdminList, delAppointment } from '@/api/movie';
import { getDepartmentList } from '@/api/admin/department';
import { export_json_to_excel } from '@/assets/js/Export2Excel';
import Dict from '@/components/common/dict';

export default {
  name: 'MovieMain',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      },
      query: {
        depid: '',
        name: '',
        telephone: '',
        orderTime: '',
        movieName: '',
        status: 1
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        depid: '',
        name: '',
        telephone: '',
        orderTime: '',
        movieName: '',
        status: 1,
        isToday: 1
      },
      list: [],
      departmentList: [],
      listLoading: true,
      dlgLoading: false,
      exportLoading: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
    this.loadDepartmentList();
  },
  methods: {
    tabClick(tab) {
      let status = tab.name;
      this.pageInfo.status = status;
      this.query.status = status;
      this.onRefresh();
    },
    changeToday(v) {
      this.pageInfo.isToday = v;
      this.fetchData();
    },
    //加载部门列表
    loadDepartmentList() {
      this.departmentList = [];
      getDepartmentList().then(response => {
        this.departmentList = response.data;
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.depid = this.query.depid;
      this.pageInfo.name = this.query.name;
      this.pageInfo.telephone = this.query.telephone;
      this.pageInfo.orderTime = this.query.orderTime;
      this.pageInfo.movieName = this.query.movieName;
      this.pageInfo.status = this.query.status;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
      this.loadDepartmentList();
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getAdminList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delAppointment({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条记录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAppointment({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //excel数据导出
    exportToExcel() {
      this.exportLoading = true;
      require.ensure([], () => {
        const tHeader = [
          '预定人名称',
          '所属部门',
          '警号',
          '联系方式',
          '提交时间',
          '预定时间',
          '预定电影名称',
          '预定票数',
          '座位号',
          '预约状态'
        ];
        const filterVal = [
          'name',
          'department',
          'policeCard',
          'telphone',
          'createTime',
          'orderTime',
          'movieName',
          'nums',
          'formatterSeat',
          'status'
        ];
        const list = this.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '用户电影预约');
        this.exportLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].status = Dict.getText(
          jsonData[i].status,
          Dict.movie_status
        );
      }
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
